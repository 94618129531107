import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const trigger = document.querySelector('#roles-trigger')
const roles = Array.from(document.querySelectorAll('.js-role'))

function hoverAnim(e) {
  const el = e.target.querySelector('.js-role-container')
  gsap.to(el, {
    y: -10,
    repeat: 3,
    yoyo: true,
    duration: 0.1,
  })
}

function stopHoverAnim(e) {
  const el = e.target.querySelector('.js-role-container')
  gsap.killTweensOf(el)
  gsap.set(el, {clearProps: 'transform'})
}

export default function() {
  const masterTl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: "top +=50%",
      onEnter: () => {
        trigger.classList.add('roles-is-active')
      },
    }
  }).add('start')

  roles.forEach((role, i) => {
    const tl = gsap.timeline()
    const expandArrows = role.querySelector('.js-role-arrows-anim')

    tl.add('start')
      .to(expandArrows, {
        opacity: 0,
        duration: 0.2,
        ease: 'circ.out'
      }, 'start')
      .set(expandArrows, {display: 'none'})
      .to(role, {
        scale: 1,
        x: 0,
        y: 0,
        duration: 0.2,
        ease: 'circ.out'
      }, 'start')

      masterTl.add(tl, `start+=${i*0.1}`)
  })

  const flowArrows = Array.from(document.querySelectorAll('.js-flow-arrows'))
  const flowTl = gsap.timeline()

  ScrollTrigger.refresh(true)

  roles.forEach(role => {
    role.addEventListener('mouseenter', hoverAnim);
    role.addEventListener('mouseleave', stopHoverAnim);
  });
}
