import expand from './modules/expand';
import timeline from './modules/timeline';
import optimism from './modules/optimism';
import missions from './modules/missions';
import nav from './modules/nav';
import roles from './modules/roles';
import modals from './modules/modals';
import content from './modules/content';
import resize from './modules/resize';

window.addEventListener('load', function() {
  nav();
  modals();
  content();
  resize();

  const animEls = Array.from(document.querySelectorAll('.js-anim'));

  animEls.forEach(el => {
    const animType = el.dataset.type;

    if (animType === 'expand') {
      expand(el);
    } else if (animType === 'timeline') {
      timeline();
    } else if (animType === 'missions') {
      missions();
    }
  })

  // Don't move this, scrollTrigger depends on ordering
  roles();
  optimism();
})

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
