import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const body = document.body
const navMenu = document.querySelector('#nav-menu')
const navShow = document.querySelector('#nav-show')
const navHide = document.querySelector('#nav-hide')
const navItems = Array.from(document.querySelectorAll('.js-nav-item'))
const navLinks = Array.from(document.querySelectorAll('.js-nav-link'))

function showNav() {
  const tl = gsap.timeline()
  tl.add('start')
  .set(navShow, {pointerEvents: 'none'})
  .set(navHide, {pointerEvents: 'auto'})
  .to(navShow, {
    opacity: 0,
    duration: 0.1,
    ease: 'none'
  }, 'start')
  .to(navHide, {
    opacity: 1,
    duration: 0.1,
    ease: 'circ.out'
  }, 'start')
  .fromTo(navMenu, {
    scaleX: 0,
    transformOrigin: 'left'
  }, {
    scaleX: 1,
    duration: 0.2,
    ease: 'circ.out'
  }, 'start')
  .fromTo(navItems, {
    opacity: 0,
    y: 20,
  }, {
    opacity: 1,
    y: 0,
    stagger: 0.05,
    duration: 0.4,
    ease: 'circ.out'
  })
}

function hideNav() {
  const tl = gsap.timeline()
  tl.add('start')
  .set(navShow, {pointerEvents: 'auto'})
  .set(navHide, {pointerEvents: 'none'})
  .to(navItems, {
    opacity: 0,
    y: 20,
    stagger: 0.01,
    duration: 0.2,
    ease: 'circ.out'
  }, 'start')
  .to(navMenu, {
    scaleX: 0,
    duration: 0.1,
    transformOrigin: 'left',
    ease: 'none'
  })
  .to(navHide, {
    opacity: 0,
    duration: 0.1,
    ease: 'none'
  }, 'start+=0.1')
  .to(navShow, {
    opacity: 1,
    duration: 0.1,
    ease: 'none'
  })
}

function handleLink(e) {
  hideNav()
  e.preventDefault()
  const selector = e.target.getAttribute('href')
  const match = document.querySelector(selector)

  if (match) {
    gsap.to(window, {
      scrollTo: match,
      duration: 0.4,
      ease: 'circ.out'
    })
  }
}

function firstAnim() {
  const text = navShow.querySelector('#nav-show-text')

  gsap.fromTo(text, {
    opacity: 0,
    y: 5,
  }, {
    opacity: 1,
    y: 0,
    duration: 0.4,
    delay: 0.8,
    ease: 'circ.out',
    immediateRender: false
  })
}

export default function() {
  navShow.addEventListener('click', showNav)
  navHide.addEventListener('click', hideNav)
  navLinks.forEach(link => {
    link.addEventListener('click', handleLink);
  })

  firstAnim()
}
