import gsap from 'gsap'

const showBtns = Array.from(document.querySelectorAll('.js-show-modal'))
const hideBtns = Array.from(document.querySelectorAll('.js-hide-modal'))

function enableBodyScroll() {
  const root = document.documentElement
  root.classList.remove('modal-is-active')
}

function disableBodyScroll() {
  const root = document.documentElement
  root.classList.add('modal-is-active')
}

function showModal(e) {
  const selector = `#${e.currentTarget.dataset.modal}`
  const modal = document.querySelector(selector)

  if (modal) {
    const modalContainer = modal.querySelector('.js-modal-container')
    disableBodyScroll()
    const tl = gsap.timeline()
    tl.set(modal, {left: 0})
      .add('start')
      .fromTo(modal, {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.2,
        ease: 'circ.out'
      }, 'start')
      .fromTo(modalContainer, {
        yPercent: 10,
      }, {
        yPercent: 0,
        duration: 0.2,
        ease: 'circ.out'
      }, 'start')
  }
}

function hideModal(e) {
  const modal = e.currentTarget.closest('.js-modal')

  if (modal) {
    const modalContainer = modal.querySelector('.js-modal-container')
    enableBodyScroll()
    const tl = gsap.timeline()
    tl.add('start')
      .to(modal, {
        opacity: 0,
        duration: 0.1,
        ease: 'none'
      }, 'start')
      .to(modalContainer, {
        yPercent: 10,
        duration: 0.1,
        ease: 'none'
      }, 'start')
      .set(modal, {left: '-100%'})
  }
}

export default function() {
  showBtns.forEach(btn => {
    btn.addEventListener('click', showModal);
  })

  hideBtns.forEach(btn => {
    btn.addEventListener('click', hideModal);
  })
}
