import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const body = document.body;

  const ro = new ResizeObserver(entries => {
    ScrollTrigger.refresh(true)
  });

  // Observe one or multiple elements
  ro.observe(body);
}
