import gsap from 'gsap';

const SELECTOR = '.js-fade-in';
const STAGGER = 0.2;

const animate = (element, index) => {
  gsap.fromTo(element, {
    opacity: 0,
    y: 10
  }, {
    duration: 0.4,
    opacity: 1,
    y: 0,
    delay: index * STAGGER
  })
}

export default function() {
  const elements = Array.from(document.querySelectorAll('.js-fade-in'))

  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry, index) => {
      // when element's is in viewport,
      // animate it!
      if (entry.intersectionRatio > 0) {
        animate(entry.target, index);
        // remove observer after animation
        observer.unobserve(entry.target);
      }
    });
  }, {threshold: 0.5});

  elements.forEach((element) => intersectionObserver.observe(element));
}
