import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function setupHeaderAnim(el) {
  const header = el.querySelector('.js-header')
  const expand = el.querySelector('.js-expand');
  const labelEls = Array.from(el.querySelectorAll('.js-label > *'));
  const topCard = el.querySelector('.js-card');
  const extraCard1 = el.querySelector('.js-card-extra-1');
  const extraCard2 = el.querySelector('.js-card-extra-2');
  const images = Array.from(el.querySelectorAll('.js-header-image'));
  const scrollPrompt = el.querySelector('#header-begin');
  const tlDelay = header.classList.contains('header--first') ? 0.4 : 0;

  // Abort if header doesn't have expand element.
  if (!expand) { return; }

  const parent = header.parentElement;
  const masterTl = gsap.timeline({
    delay: tlDelay,
    scrollTrigger: {
      trigger: header,
      start: "top +=25%",
      toggleActions: "play none none reverse",
      onEnter: () => {
        parent.classList.add('header-is-active')
      },
      onLeaveBack: () => {
        parent.classList.remove('header-is-active')
      },
    }
  });

  const headerTl = gsap.timeline();
  headerTl.set(expand, {className: "expand is-active"})
  .add('start')
  .to(labelEls.reverse(), {
    opacity: 0,
    duration: 0.2,
    y: 100,
    stagger: 0.1,
    ease: 'circ.out'
  }, 'start')
  .to(expand, {
    width: '100%',
    height: '100%',
    duration: 0.8,
    delay: 0.2,
    ease: 'circ.out'
  }, 'start')
  .fromTo(topCard, {
    scale: 0,
    opacity: 0
  },
  {
    scale: 1,
    opacity: 1,
    duration: 0.4,
    delay: 0.4,
    ease: 'circ.inOut'
  }, 'start')
  .add('cards', 0.4)
  .fromTo(extraCard1, {
    xPercent: -10,
    yPercent: 10,
    opacity: 0
  },
  {
    xPercent: 0,
    yPercent: 0,
    opacity: 1,
    duration: 0.4,
    delay: 0.4,
    ease: 'circ.inOut'
  }, 'cards')
  .fromTo(extraCard2, {
    x: 10,
    y: -10,
    opacity: 0
  },
  {
    x: 0,
    y: 0,
    opacity: 1,
    duration: 0.4,
    delay: 0.6,
    ease: 'circ.inOut'
  }, 'cards')
  .add('images', 0.6)
  .fromTo(images, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.4,
    delay: 0.4,
    stagger: 0.1,
    ease: 'circ.inOut'
  }, 'images')
  .from(images, {
    x: (i, target) => {
      const pos = target.dataset.pos
      if (pos.includes('left')) {
        return 100
      } else if (pos.includes('right')) {
        return -100
      }
    },
    y: (i, target) => {
      const pos = target.dataset.pos
      if (pos.includes('top')) {
        return 100
      } else {
        return -100
      }
    },
    scale: 0.5,
    duration: 0.4,
    delay: 0.4,
    stagger: 0.1,
    ease: 'circ.inOut'
  }, 'images')

  let loaderTl;
  if (header.classList.contains('header--first')) {
    loaderTl = gsap.timeline({repeat: 1, repeatDelay: 0.6, ease: "none"});
    const arrows = Array.from(header.querySelector('.js-expand').children)
    loaderTl
    .to(arrows, {
      xPercent: (i, el) => {
        const xDir = el.dataset.x
        let val
        switch (xDir) {
          case "l":
            val = -40;
            break;
          case "r":
            val = 40;
            break;
        }
        return val;
      },
      yPercent: (i, el) => {
        const yDir = el.dataset.y
        let val
        switch (yDir) {
          case "t":
            val = -40;
            break;
          case "b":
            val = 40;
            break;
        }
        return val;
      },
      opacity: 0,
      duration: 0.2
    })
    .fromTo(arrows, {
      xPercent: (i, el) => {
        const xDir = el.dataset.x
        let val
        switch (xDir) {
          case "l":
            val = 40;
            break;
          case "r":
            val = -40;
            break;
        }
        return val;
      },
      yPercent: (i, el) => {
        const yDir = el.dataset.y
        let val
        switch (yDir) {
          case "t":
            val = 40;
            break;
          case "b":
            val = -40;
            break;
        }
        return val;
      },
      opacity: 0
    }, {
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      duration: 0.2,
      delay: 0.4,
      immediateRender: false
    })
  }

  if (loaderTl) {
    masterTl.add(loaderTl)
  }

  masterTl.add(headerTl)

  let promptTl;
  if (scrollPrompt) {
    promptTl = gsap.timeline();
    promptTl.fromTo(scrollPrompt, {
      opacity: 0,
      y: -20
    }, {
      opacity: 1,
      y: 0,
      duration: 0.2,
      immediateRender: false
    })

    const arrow = scrollPrompt.querySelector('#begin-arrow');
    const arrowTl = gsap.timeline({delay: 0.4, repeat: 2, repeatDelay: 0.4, ease: 'none'});
    arrowTl
    .fromTo(arrow, {
      opacity: 1,
      y: 0
    }, {
      duration: 0.4,
      opacity: 0,
      y: 16
    })
    .fromTo(arrow, {
      opacity: 0,
      y: -16
    }, {
      duration: 0.4,
      opacity: 1,
      y: 0,
      immediateRender: false
    })

    promptTl.add(arrowTl)
  }

  if (promptTl) {
    masterTl.add(promptTl)
  }
}

export default function(el) {
  setupHeaderAnim(el)
}
