import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

let btn;
let hoverTl;

function handleHover() {
  hoverTl.restart()
}

function checkScrollArrow(el) {
  const scrollArrow = el.querySelector('.js-tl-scroll-arrow')
  if (scrollArrow) {
    const arrowTl = gsap.timeline({delay: 0.4, repeat: 2, repeatDelay: 0.2})
    arrowTl
    .fromTo(scrollArrow, {
      opacity: 1,
      x: 0
    }, {
      duration: 0.2,
      ease: "none",
      opacity: 0,
      x: 10
    })
    .fromTo(scrollArrow, {
      opacity: 0,
      x: -10
    }, {
      duration: 0.2,
      ease: "none",
      opacity: 1,
      x: 0,
      immediateRender: false
    })
  }
}

function initObserver() {
  const STAGGER = 0.1;
  const items = Array.from(document.querySelectorAll('.js-tl-fade-in'))

  const animate = (element, index) => (
    gsap.fromTo(element, {
      opacity: 0,
      y: 10
    }, {
      duration: 0.4,
      opacity: 1,
      y: 0,
      delay: index * STAGGER
    })
  );

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry, index) => {
      // when element's is in viewport,
      // animate it!
      if (entry.intersectionRatio > 0) {
        animate(entry.target, index);
        checkScrollArrow(entry.target)
        // remove observer after animation
        observer.unobserve(entry.target);
      }
    });
  }, {threshold: 0.5});

  items.forEach((item) => observer.observe(item));
}

function initTimeline() {
  const parent = document.querySelector('.js-tl')
  const timelineInner = parent.querySelector('.js-tl-inner')
  const timelineContainer = parent.querySelector('.js-tl-container')
  const diff = timelineInner.scrollWidth - parent.offsetWidth
  const tl = gsap.timeline()
  const rootEl = document.documentElement
  rootEl.classList.add('timeline-is-expanding')

  tl.add('start')
  tl.fromTo(timelineContainer, {
    height: '0'
  },
  {
    duration: 0.2,
    height: '100vh',
    opacity: 1,
    ease: 'none',
    onComplete: function() {
      gsap.to(timelineInner, {
        x: -diff,
        ease: 'none',
        scrollTrigger: {
          trigger: parent,
          pin: true,
          scrub: true,
          end: `+=${parent.offsetWidth*2}`
        }
      })

      ScrollTrigger.sort((a, b) => {
        return a.trigger.dataset.scrollOrder - b.trigger.dataset.scrollOrder
      })
      ScrollTrigger.refresh(true)
      initObserver()
    }
  }, 'start')
  .to(window, {
    duration: 0.2,
    scrollTo: parent,
    onComplete: function() {
      rootEl.classList.remove('timeline-is-expanding')
    }
  }, 'start')

  btn.removeEventListener('click', initTimeline)
}

function initHoverTl() {
  const arrows = Array.from(btn.querySelectorAll('.js-expand-tl-arrow'))
  hoverTl = gsap.timeline({paused: true})
  hoverTl
  .to(arrows, {
    xPercent: (i, el) => {
      const xDir = el.dataset.x
      let val
      switch (xDir) {
        case "l":
          val = -30;
          break;
        case "r":
          val = 30;
          break;
      }
      return val;
    },
    yPercent: (i, el) => {
      const yDir = el.dataset.y
      let val
      switch (yDir) {
        case "t":
          val = -30;
          break;
        case "b":
          val = 30;
          break;
      }
      return val;
    },
    opacity: 0,
    duration: 0.1
  })
  .fromTo(arrows, {
    xPercent: (i, el) => {
      const xDir = el.dataset.x
      let val
      switch (xDir) {
        case "l":
          val = 30;
          break;
        case "r":
          val = -30;
          break;
      }
      return val;
    },
    yPercent: (i, el) => {
      const yDir = el.dataset.y
      let val
      switch (yDir) {
        case "t":
          val = 30;
          break;
        case "b":
          val = -30;
          break;
      }
      return val;
    },
  }, {
    xPercent: 0,
    yPercent: 0,
    opacity: 1,
    duration: 0.1,
    delay: 0.3,
    immediateRender: false
  })
}

export default function() {
  btn = document.querySelector('#expand-tl');
  initHoverTl();
  btn.addEventListener('click', initTimeline);
  btn.addEventListener('mouseenter', handleHover);
}
