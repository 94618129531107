import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const sections = gsap.utils.toArray('.js-mission')
  const parent = document.querySelector('.js-missions')
  const container = document.querySelector('.js-missions-container')
  const height = 100 * sections.length

  const pinner = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top top",
      end: `+=${height}%`,
      scrub: true,
      pin: container,
      id: 'pinning'
    }
  })

  const tl = gsap.timeline({
    duration: sections.length,
    scrollTrigger: {
      trigger: parent,
      start: "top top",
      end: `+=${height}%`,
      scrub: true,
      id: 'sections',
    }
  })

  sections.forEach((section, i) => {
    gsap.set(section, {position: "absolute", top: 0})

    const height = section.offsetHeight
    const card = section.querySelector('.js-card')
    const image = section.querySelector('.js-mission-image')

    // Best to handle everything in gsap, otherwise it can get messy trying to reverse animations applied with a css class etc
    // Don't fade in the first image and card
    // Had to tweak the positions of the tweens in the timeline to get crossover effect
    if (i !== 0) {
      tl.from(image, {autoAlpha:0, duration: 0.1}, i*0.5)
      tl.from(card, {autoAlpha: 0, duration: 0.1}, i*0.5)
    }

    tl.to(card, {
      y: -parent.offsetHeight,
      ease: "none"
    }, i*0.5)

    if (i == 0) {
      tl.to(image, {autoAlpha:0, duration: 0.1}, 0.5)
    } else if (i !== sections.length-1) {
      tl.to(image, {autoAlpha:0}, i)
    }
  })

  ScrollTrigger.refresh(true)
}
