import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

let btn;
let hoverTl;

function handleHover() {
  hoverTl.restart()
}

function initHoverTl() {
  const arrows = Array.from(btn.querySelectorAll('.js-expand-tl-arrow'))
  hoverTl = gsap.timeline({paused: true})
  hoverTl
  .to(arrows, {
    xPercent: (i, el) => {
      const xDir = el.dataset.x
      let val
      switch (xDir) {
        case "l":
          val = -30;
          break;
        case "r":
          val = 30;
          break;
      }
      return val;
    },
    yPercent: (i, el) => {
      const yDir = el.dataset.y
      let val
      switch (yDir) {
        case "t":
          val = -30;
          break;
        case "b":
          val = 30;
          break;
      }
      return val;
    },
    opacity: 0,
    duration: 0.1
  })
  .fromTo(arrows, {
    xPercent: (i, el) => {
      const xDir = el.dataset.x
      let val
      switch (xDir) {
        case "l":
          val = 30;
          break;
        case "r":
          val = -30;
          break;
      }
      return val;
    },
    yPercent: (i, el) => {
      const yDir = el.dataset.y
      let val
      switch (yDir) {
        case "t":
          val = 30;
          break;
        case "b":
          val = -30;
          break;
      }
      return val;
    },
  }, {
    xPercent: 0,
    yPercent: 0,
    opacity: 1,
    duration: 0.1,
    delay: 0.3,
    immediateRender: false
  })
}

function initObserver() {
  const STAGGER = 0.1;
  const items = Array.from(document.querySelectorAll('.js-opt-fade-in'))

  const animate = (element, index) => {
    gsap.fromTo(element, {
      opacity: 0,
      y: 10
    }, {
      duration: 0.4,
      opacity: 1,
      y: 0,
      delay: index * STAGGER
    })
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry, index) => {
      // when element's is in viewport,
      // animate it!
      if (entry.intersectionRatio > 0) {
        animate(entry.target, index);
        // remove observer after animation
        observer.unobserve(entry.target);
      }
    });
  }, {threshold: 0.5});

  items.forEach((item) => observer.observe(item));
}

function expandSection() {
  const el = document.querySelector('#optimism')
  const tl = gsap.timeline()
  const rootEl = document.documentElement;
  rootEl.classList.add('optimism-is-expanding')

  tl
  .set(el, {height: 'auto'})
  .add('start')
  .from(el, {
    height: '0',
    opacity: 0,
    duration: 0.2,
    ease: 'none',
    immediateRender: false,
    onComplete: function() {
      ScrollTrigger.refresh(true)
      initObserver()
    }
  }, 'start')
  .to(window, {
    duration: 0.2,
    scrollTo: el,
    onComplete: function() {
      rootEl.classList.remove('optimism-is-expanding')
    }
  }, 'start')

  btn.removeEventListener('click', expandSection)
}

export default function() {
  btn = document.querySelector('#expand-optimism');
  initHoverTl();
  btn.addEventListener('click', expandSection)
  btn.addEventListener('mouseenter', handleHover);
}
